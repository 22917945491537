import React from 'react';

import { PurchasedRewardTableDisplayData } from '../../../../../../src/types/rewards';
import { DataGrid, GridColDef, GridPagination, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import './RedeemedRewardsTable.css'

export interface RedeemedRewardsTableProps {
    redeemedRewardsRows: PurchasedRewardTableDisplayData[],
    rowsLoading: boolean;
}

export function RedeemedRewardsTable (props: RedeemedRewardsTableProps) {
    const {
        redeemedRewardsRows,
        rowsLoading
    } = props;

    let columns: GridColDef[] = [
        {
            field: 'displayName',
            headerName: 'Reward Name',
            width: 220
        },
        {
            field: 'recipientName',
            headerName: 'Recipient',
            width: 130
        },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 130
        },
        {
            field: 'created',
            headerName: 'Redeemed Date',
            width: 130,
            valueFormatter: (params) => moment(params).format('MM/DD/YYYY')
        },
        {
            field: 'rewardOwnerName',
            headerName: 'Reward Owner',
            width: 130
        },
        {
            field: 'cost',
            headerName: 'Cost',
            width: 80
        },
        {
            field: 'redeemInstructions',
            headerName: 'Redeem Instructions',
            width: 220,
        }
    ];

    const noRowsOverlay = () => {
        return (
            <Box>
                <Typography className='rewards-no-rows-text'>None</Typography>
            </Box>
        )
    }

    function CustomFooter() {
        return (
            <div className='redeemed-rewards-footer'>
                <div className='redeemed-rewards-export'>
                    <GridToolbarExport/>
                </div>
                <GridPagination className='redeemed-rewards-footer-pagination'/>
            </div>
        );
    }

    return (
        <Box className='redeemed-rewards-data-grid-box'>
            <DataGrid 
                rows={redeemedRewardsRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 15,
                        },
                    },
                }}
                slots={{
                    noRowsOverlay,
                    footer: CustomFooter,
                }}
                pageSizeOptions={[15, 25, 50]}
                loading={rowsLoading}
            />
        </Box>
    )
}