import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { Avatar, CircularProgress, createTheme, Divider, Tooltip, Typography } from '@mui/material';

import { UserData, UserKredsCount } from '../../../src/types/user';
import './UserSummary.css';
import { getUserAvatar } from '../util/images';
import HelpOutline from '@mui/icons-material/HelpOutline';

export interface UserSummaryProps {
    user: UserData,
    userKreds?: UserKredsCount,
    rewardsDisabled?: boolean,
}

export function UserSummary(props: UserSummaryProps) {
    const {
        user,
        userKreds,
        rewardsDisabled
    } = props;
    const theme = createTheme({
        typography: {
            fontFamily: 'Catamaran'
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <div className='user-summary'>
                <Avatar
                    className='user-side-bar-avatar'
                    {...getUserAvatar(user.profilePhotoImageUrl)}
                >{user.firstName[0]}{user.lastName[0]}</Avatar>
                <p className='user-display-name'>{`${user.firstName} ${user.lastName}`}</p>
                {user.roleAtSchool && <Typography className='user-side-bar-user-role'>{user.roleAtSchool}</Typography>}
                { !userKreds ? <CircularProgress /> :
                    <div>
                        <div className='user-side-bar-kreds'>
                            <div className='to-give' >
                                <Tooltip 
                                    title= {
                                        <Typography className='user-kreds-tool-tip-text'>This is the total number of hilights you have sent this school year!</Typography>
                                    }
                                    arrow
                                    placement={'top'}
                                >
                                    <div> 
                                        <p className='kred-count'>{userKreds?.hilightsSent}</p>
                                        {/* <p className='kred-type'>Given By You</p> */}
                                        <p className='kred-type'>
                                            <span>Hilights Sent</span> <br/>
                                            <span>By You</span>
                                        </p>
                                        
                                    </div>
                                </Tooltip>
                            </div>
                            <Divider orientation='vertical' className='kreds-divider' flexItem/>
                            <div className='earned' >
                                <Tooltip 
                                    title= {
                                        <div>
                                            <Typography className='user-kreds-tool-tip-text'>This is the total number of hilights you have received this school year.</Typography>
                                        
                                            {!rewardsDisabled && 
                                                <div>
                                                    <br/>
                                                    <Typography className='user-kreds-tool-tip-text'>This is NOT your points earned that you can spend in the rewards marketplace.</Typography>
                                                </div>
                                            }
                                        </div>
                                    }
                                    arrow
                                    placement={'top'}
                                >
                                    <div>
                                        <p className='kred-count'>{userKreds?.hilightsReceived}</p>
                                        {/* <p className='kred-type'>Given To You</p> */}
                                        <p className='kred-type'>
                                            <span>Hilights Given</span> <br/>
                                            <span>To You</span>
                                        </p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        { !rewardsDisabled && userKreds.currentPoints > 0 &&
                            <div className='points-count'>
                                <Typography>
                                    You have <span className='points-count-text'>{userKreds.currentPoints}</span> points to redeem in  <br /> the Rewards Marketplace
                                </Typography>
                            </div>
                        }
                        
                    </div>
                }
            </div>

        </ThemeProvider>
    )
}


