import React from 'react';

import { UserKredsToRedeemData } from '../../../../../../src/types/rewards';
import { DataGrid, GridColDef, GridPagination, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

import './StaffPointsTable.css'

export interface StaffPointsTableProps {
    staffPointsRows: UserKredsToRedeemData[],
    rowsLoading: boolean;
}

export function StaffPointsTable (props: StaffPointsTableProps) {
    const {
        staffPointsRows,
        rowsLoading
    } = props;

    let columns: GridColDef[] = [
        {
            field: 'fullName',
            headerName: 'Name',
            width: 250
        },
        {
            field: 'pointsToRedeem',
            headerName: 'Points To Redeem',
            width: 130
        },
    ];

    const noRowsOverlay = () => {
        return (
            <Box>
                <Typography className='rewards-no-rows-text'>None</Typography>
            </Box>
        )
    }

    function CustomFooter() {
        return (
            <div className='staff-points-footer'>
                <div className='staff-points-export'>
                    <GridToolbarExport/>
                </div>
                <GridPagination className='staff-points-footer-pagination'/>
            </div>
        );
    }

    return (
        <Box className='staff-points-data-grid-box'>
            <DataGrid 
                rows={staffPointsRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 15,
                        },
                    },
                }}
                slots={{
                    noRowsOverlay,
                    footer: CustomFooter,
                }}
                pageSizeOptions={[15, 25, 50]}
                loading={rowsLoading}
            />
        </Box>
    )
}