import React, {useEffect, useRef, useState} from 'react';
import * as Sentry from "@sentry/react";
import { Box, FormControl,  MenuItem, Select, SelectChangeEvent, IconButton, Tooltip, Tab, Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { authSelector } from '../../../store/authSlice';
import { SpotlightContent } from './SpotlightContent';
import { MainServiceApi } from '../../../services/mainService';
import { SpotlightData } from '../../../../../src/types/spotlight';
import { ChartColors, DefaultColor } from '../Data/Data';
import { PermissionServiceApi } from '../../../services/permissionService';
import { School } from '../../../../../src/types/user';

import './Spotlight.css';

export interface SpotlightProps {
    currentSchool: School | undefined;
    districtId: number;
    userId: number;
}
export function Spotlight(props: SpotlightProps) {
    const { currentSchool, districtId, userId } = props;

    const authDataStore = useSelector(authSelector);
	const { user, token } = authDataStore;

    // Default start date is 7 days ago
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    defaultStartDate.setHours(0,0,0,0);
    const [ timeRangeSelection, setTimeRangeSelection] = useState('week');
    const [ startDate, setStartDate ] = useState<Date>(defaultStartDate);

    const [ showDistrictData, setShowDistrictData ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState('school');

    // School drop down (only appears  if user has correct permission, otherwise just shows user's current school)
    const [selectedSchool, setSelectedSchool] = useState<School | undefined>(currentSchool);
    const [districtSchools, setDistrictSchools] = useState<School[]>([]);
    const [userHasPermissionToViewDistrictSchools, setUserHasPermissionToViewDistrictSchools] = useState(false);
    // If the passed in school changes, update the selected school
    useEffect(() => {
        setSelectedSchool(currentSchool);
    }, [currentSchool]);
    
    const spotlightDescriptionText: string = `Your ${selectedTab}'s ${timeRangeSelection}ly spotlight! How has your ${selectedTab} been doing for the past ${timeRangeSelection === 'week' ? 7 : 30} days?`

    const handleChangeTimeSelection = (event: SelectChangeEvent) => {
        setTimeRangeSelection(event.target?.value);
        const days = event.target?.value === 'week' ? 7 : 30
        const newStartDate = new Date();
        newStartDate.setDate(newStartDate.getDate() - days);
        newStartDate.setHours(0,0,0,0);
        setStartDate(newStartDate);
    }

    const [ spotlightData, setSpotlightData ] = useState<SpotlightData | undefined>()
    const [isLoadingSpotlightData, setIsLoadingSpotlightData] = useState(true);
    const [ tokenTypesChartLegend, setTokenTypesChartLegend] = useState<{id: string,
        color: string,
        value: string
    }[]>([]);

    // Get data 
    const getSpotlightData = async (recognitionStartDate: Date, districtOrSchool: string, school?: School) => {
        const mainServiceApi = MainServiceApi();
        
        try {
            setIsLoadingSpotlightData(true);
            let fetchedSpotlightData: SpotlightData | undefined;
            if (districtOrSchool === 'district') {
                fetchedSpotlightData = await mainServiceApi.getSpotlightDataForDistrict({districtId, startDate: recognitionStartDate, token: token!});
            } else {
                if (school) {
                    fetchedSpotlightData = await mainServiceApi.getSpotlightDataForSchool({schoolId: school.id, startDate: recognitionStartDate, token: token!});
                }
                
            }
            
            if (fetchedSpotlightData) {
                setSpotlightData(fetchedSpotlightData)
            }

            if (fetchedSpotlightData && fetchedSpotlightData.tokenTypes.length > 0) {
                // Build the legend based off of the token types
            const chartLegendList: {
                id: string,
                color: string,
                value: string,
            }[] = fetchedSpotlightData.tokenTypes.map((tokenType, index) => {
                return {
                    id: tokenType.tokenCoreValue,
                    color: ChartColors[index % ChartColors.length],
                    value: tokenType.tokenCoreValue
                }
            });
            // Add the "Other" category
            chartLegendList.push({
                id: '-1',
                color: DefaultColor,
                value: 'Other'
            })
            setTokenTypesChartLegend(chartLegendList);
            }
            setIsLoadingSpotlightData(false);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }

    useEffect(() => {
        if (selectedSchool && token) {
            getSpotlightData(startDate, selectedTab, selectedSchool);
        }
    }, [selectedSchool, startDate, selectedTab]);

    // Get the list of schools for the drop down
    const getDistrictSchools = async (districtId: number, token: string): Promise<void> => {
        const mainServiceApi = MainServiceApi();
        const districtSchools = await mainServiceApi.getDistrictSchools(districtId, token);
        setDistrictSchools(districtSchools);
    } 

    // Printing
    const spotlightRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => spotlightRef.current,
    });

    // Permrissions
    useEffect(() => {
		if (districtId && token && user) {
			getDistrictLevelPermissions(districtId);
            getUserPermissions(user.id, token);
		}
	}, [districtId]);

	const getDistrictLevelPermissions = async (districtId: number) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			
			const districtFeedKey = 'DISTRICT_VIEW_FEED'; // using the same permission that controls viewing district data on the feed tab (for simplicity)
			const districtHasPermissionToViewDistrictFeed = districtPermissions.find(permission => permission.permissionKey === districtFeedKey);
			if (districtHasPermissionToViewDistrictFeed) {
				setShowDistrictData(true);
			} else {
				setShowDistrictData(false);
			}

		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

    const getUserPermissions = async (userId: number, token: string): Promise<void> => {
        const permissionService = PermissionServiceApi();
        const userPermissions = await permissionService.getPermissionsForUser(user!.id, token);
        const districtDashboardViewKey = 'DISTRICT_DATA_DASHBOARD';
		const userHasDistrictViewPermission = userPermissions.find(permission => permission.permissionKey === districtDashboardViewKey);
        if (userHasDistrictViewPermission) {
            setUserHasPermissionToViewDistrictSchools(true);
            getDistrictSchools(districtId, token!);
        } else {
            setUserHasPermissionToViewDistrictSchools(false);
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    	setSelectedTab(newValue);
  	};

    return (
        <div className='teacher-spotlight-page' >
            <Box className='spotlight-page-header'>
                <Box className='page-heading'>Spotlight</Box>
                    <Box className='spotlight-header-options'>
                        {!isMobile && 
                        <Tooltip title='Print the Spotlight or Save as PDF' arrow>
                            <IconButton className='print-icon' onClick={handlePrint}>
                                <PrintIcon/>
                            </IconButton>
                        </Tooltip>
                        }
                        <FormControl size='small' className={`spotlight-dropdown${isMobile ? '-mobile' : ''}`} sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                id='spotlight-time-select'
                                value={timeRangeSelection}
                                onChange={handleChangeTimeSelection}
                            >
                                <MenuItem key='week' value='week'>Weekly</MenuItem>
                                <MenuItem key='month' value='month'>Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
            </Box>
            
            {/* Print view of the spotlight */}
            <Box style={{display: 'none'}}>
                {selectedSchool && 
                    <SpotlightContent 
                        schoolId={selectedSchool.id}
                        districtId={districtId}
                        userId={userId}
                        startDate={startDate}
                        timeRangeSelection={timeRangeSelection}
                        districtOrSchool={selectedTab}
                        spotlightData={spotlightData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        isLoadingSpotlightData={isLoadingSpotlightData}
                        ref={spotlightRef} // pass through the ref for printing
                        printView={true}
                    />
                }
            </Box>

            {/* Spotlight content - if district view is allowed, then show tabs, otherwise just show school data */}
            {!showDistrictData && selectedSchool ?
                <Box >
                    <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                    <SpotlightContent 
                        schoolId={selectedSchool.id}
                        districtId={districtId}
                        userId={userId}
                        startDate={startDate}
                        timeRangeSelection={timeRangeSelection}
                        districtOrSchool={selectedTab}
                        spotlightData={spotlightData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        isLoadingSpotlightData={isLoadingSpotlightData}
                        ref={undefined} // don't pass through the ref because we aren't using this for printing
                    /> 
                </Box>
            :
                <TabContext value={selectedTab}>
                    <Box className='spotlight-tabs'>
                        <TabList onChange={handleTabChange}>
                            <Tab label="School" value='school'/>
                            <Tab label="District" value='district'/>
                        </TabList>
                    </Box>
                    <TabPanel value='school'>
                    <Box > 
                        {userHasPermissionToViewDistrictSchools &&
                            <Box className='spotlight-school-dropdown'>
                                <Autocomplete
                                    disablePortal
                                    id="data-dashboard-school-select"
                                    options={districtSchools}
                                    value={selectedSchool || null}
                                    getOptionLabel={(option) =>  option.name }
                                    renderInput={(params) =>  <TextField {...params} label="Select a School in your District" />}
                                    onChange={(event: any, value: School | null) => {
                                        setSelectedSchool(value || undefined);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                                </Box>
                        }
                        <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                        {selectedSchool && 
                            <SpotlightContent 
                                schoolId={selectedSchool.id}
                                districtId={districtId}
                                userId={userId}
                                startDate={startDate}
                                timeRangeSelection={timeRangeSelection}
                                districtOrSchool={selectedTab}
                                spotlightData={spotlightData}
                                tokenTypesChartLegend={tokenTypesChartLegend}
                                isLoadingSpotlightData={isLoadingSpotlightData}
                                ref={undefined} // don't pass through the ref because we aren't using this for printing
                            />
                        } 
                    </Box>
                    </TabPanel>
                    <TabPanel value='district'>
                    <Box >
                        <Box className='spotlight-description-text'>{spotlightDescriptionText}</Box>
                        {selectedSchool && 
                            <SpotlightContent 
                                schoolId={selectedSchool.id}
                                districtId={districtId}
                                userId={userId}
                                startDate={startDate}
                                timeRangeSelection={timeRangeSelection}
                                spotlightData={spotlightData}
                                districtOrSchool={selectedTab}
                                tokenTypesChartLegend={tokenTypesChartLegend}
                                isLoadingSpotlightData={isLoadingSpotlightData}
                                ref={undefined} // don't pass through the ref because we aren't using this for printing
                            /> 
                        }
                    </Box>
                    </TabPanel>
                </TabContext>
            }
             
        </div>
    )
}
